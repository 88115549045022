import React, { useState, useEffect, useRef } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../axiosInstance/axiosInstance';


import { motion } from 'framer-motion';
import StageUpdated from '../UpdatedDropdowns/StageUpdated';
import { Tabs, Tab, Box } from '@mui/material';

import CategoryUpdated from '../UpdatedDropdowns/CategoryUpdated';
import PriorityUpdated from '../UpdatedDropdowns/PriorityUpdated';
import AgencyUpdated from '../UpdatedDropdowns/AgencyUpdated';
import AssigneeUpdated from '../UpdatedDropdowns/AssigneeUpdated';


import SourceUpdated from '../UpdatedDropdowns/SourceUpdated';

const UpdateStudentForm = ({ setModalVisible, onClose, setSelectedItemData1, selectedItemData1, client, setClients }) => {

    const [isCategoryOpen, setIsCategoryOpen] = useState(false)

    const [getComment, setGetComment] = useState('')
    const [menus, setMenu] = useState(false);
    const [error, setError] = useState(null);
    const [degreeTabs, setDegreeTabs] = useState([]);


    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showComments, setShowComments] = useState([])

    const [nextUrl, setNextUrl] = useState(null);
    const [isCountryOpen, setIsCountryOpen] = useState(false)
    const [selectedDegreeIndex, setSelectedDegreeIndex] = useState(0);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsCountryOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);



    const scrollDivRef = useRef(null);
    const fetchComments = async (reset = true) => {
        try {
            setLoading(true);
            const url = reset ? `kanban/api/v1/comments/?limit=3&search=&client=${selectedItemData1.id}` : nextUrl;

            const response = await axiosInstance.get(url);
            const newComments = reset ? response.data.results : [...showComments, ...response.data.results];

            setShowComments(newComments);
            setTotalCount(response.data.count);
            setNextUrl(response.data.next);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching comments:', error);
            setLoading(false);
        }
    };
    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await axiosInstance.get('account/api/v1/module_menus/');
                setMenu(response.data);
            } catch (error) {
                console.error('Error fetching icons:', error);
                setError(error);
            }
        };

        fetchIcons();
    }, []);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 && nextUrl) {
    //             fetchComments(false);
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [nextUrl, loading]);

    useEffect(() => {
        fetchComments();
    }, [selectedItemData1.id]);

    const commentSubmit = async (event) => {
        event.preventDefault();
        try {
            const data = {
                "comment": getComment,
                "client": selectedItemData1.id
            };

            const response = await axiosInstance.post('kanban/api/v1/comments/', data);
            console.log('Response from second API:', response.data);


            if (response.status === 201) {

                fetchComments();
                setGetComment("");
            }
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            commentSubmit(event); // Call handleSubmit when Enter key is pressed
        }
    };



    const toggleListCategory = (itemId) => {
        setIsCategoryOpen(itemId === isCategoryOpen ? null : itemId);


    }
    const handleCategoryChange = async (categoryID, client) => {
        try {
            console.log("client", client);

            await axiosInstance.patch(`kanban/api/v1/client/${client.id}/`, { 'category': categoryID });

            setIsCategoryOpen(false)

        } catch (error) {
            console.error('Error updating category:', error);
        }
    };






    const [options, setOptions] = useState({
        categories: [],
        stages: [],
        priorities: [],
        agencies: [],
        degrees: [],
        staffs: [],
        countries: [],
        sources: [],

    });



    useEffect(() => {
        // Fetch options from backend APIs when the component mounts
        const fetchOptions = async () => {
            try {
                // Fetch categories from backend API
                const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    categories: categoriesResponse.data
                }));

                // Fetch stages from backend API
                const stagesResponse = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    stages: stagesResponse.data
                }));
                const agenciesResponse = await axiosInstance.get('kanban/api/v1/agency_dropdown/?limit=&search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    agencies: agenciesResponse.data
                }));

                // Fetch priorities from backend API
                const prioritiesResponse = await axiosInstance.get(`kanban/api/v1/priority_dropdown/?limit=&search=&stage=`);
                setOptions(prevOptions => ({
                    ...prevOptions,
                    priorities: prioritiesResponse.data
                }));

                const degreesResponse = await axiosInstance.get('kanban/api/v1/degree_dropdown/?search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    degrees: degreesResponse.data
                }));
                setDegreeTabs(degreesResponse.data);
                const staffsResponse = await axiosInstance.get('kanban/api/v1/assign_staff');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    staffs: staffsResponse.data
                }));
                const countriesResponse = await axiosInstance.get('kanban/api/v1/country_dropdown');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    countries: countriesResponse.data
                }));
                const sourcesResponse = await axiosInstance.get('kanban/api/v1/source_dropdown/');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    sources: sourcesResponse.data
                }));


            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedItemData1(prevData => ({
            ...prevData,

            [name]: value

        }));

    };





    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const clientCategory = selectedItemData1.category ? selectedItemData1.category.id : null
            const clientStage = selectedItemData1.stage ? selectedItemData1.stage.id : null
            const clientPriority = selectedItemData1.priority ? selectedItemData1.priority.id : null
            const clientAgency = selectedItemData1.agency ? selectedItemData1.agency.id : null

            const clientDegree = selectedItemData1.degree ? selectedItemData1.degree.id : null
            const clientStaff = selectedItemData1.staff ? selectedItemData1.staff.id : null
            const clientCountry = selectedItemData1.country ? selectedItemData1.country.id : null;
            const clientSource = selectedItemData1.source ? selectedItemData1.source.id : null;

            const clientUpdateForm = {
                'name': selectedItemData1.name,
                'mobile': selectedItemData1.mobile,
                'course': selectedItemData1.course,
                'passed_year': selectedItemData1.passed_year,
                'percentage': selectedItemData1.percentage,
                'description': selectedItemData1.description,
                'category': clientCategory ? parseInt(clientCategory) : selectedItemData1.category,
                'stage': clientStage ? parseInt(clientStage) : selectedItemData1.stage,
                'priority': clientPriority ? parseInt(clientPriority) : selectedItemData1.priority,
                'agency': clientAgency ? parseInt(clientAgency) : selectedItemData1.agency.id,

                'degree': clientDegree ? parseInt(clientDegree) : selectedItemData1.degree,
                'assignee': clientStaff ? parseInt(clientStaff) : selectedItemData1.assignee.id,
                'preferred_country': clientCountry ? parseInt(clientCountry) : selectedItemData1.preferred_country,
                'source': clientSource ? parseInt(clientSource) : selectedItemData1.source,
            }
            console.log(selectedItemData1);

            await axiosInstance.put(`kanban/api/v1/client/${selectedItemData1.id}/`, clientUpdateForm);
            setModalVisible(false)


        } catch (error) {
            console.error('Error updating data:', error);

        }
    };



    const toggleListPriority = async (selectedStageId) => {
        try {
            const prioritiesResponse = await axiosInstance.get(`kanban/api/v1/priority_dropdown/?limit=&search=&stage=${selectedStageId}`);
            setOptions(prevOptions => ({
                ...prevOptions,
                priorities: prioritiesResponse.data
            }));
        } catch (error) {
            console.error('Error fetching priorities:', error);

        }


    };
    const handleCountryToggle = () => {
        setIsCountryOpen(!isCountryOpen);
    };


    const handleCountrySelect = (value) => {
        setSelectedItemData1(prevFormData => ({
            ...prevFormData,
            preferred_country: value, // Set single value
        }));

        setIsCountryOpen(false);
    };

    const handleTagRemove = (e) => {
        e.stopPropagation();
        setSelectedItemData1(prevFormData => ({
            ...prevFormData,
            preferred_country: '', // Reset to empty value
        }));
        setIsCountryOpen(false);
    };
    const handleDegreeTabChange = (event, newValue) => {

        setSelectedItemData1(prevFormData => ({
            ...prevFormData,
            degree: degreeTabs[newValue].id,
        }));
    };
    useEffect(() => {
        if (degreeTabs.length > 0 && selectedItemData1?.degree) {
            const initialDegreeIndex = degreeTabs.findIndex(degree => degree.id === selectedItemData1.degree);
            if (initialDegreeIndex !== -1) {
                setSelectedDegreeIndex(initialDegreeIndex);
            }
        }
    }, [degreeTabs, selectedItemData1]);
    const userInfoString = localStorage.getItem('user_info');
    const userInfo = JSON.parse(userInfoString);

    return (
        <div>
            <div className='fixed inset-0 z-50 rounded-md h-[100vh] overflow-y-auto'>
                <motion.div
                    className=" fixed inset-0 bg-black bg-opacity-70"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={onClose}
                />
                <>
                    <motion.div
                        className=" p-6   z-10  "

                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }}

                    >

                        <div className='absolute z-10 top-[-30px] w-full'>
                            <div className="flex items-center justify-center py-10">
                                <div className='bg-white  w-[760px] relative  rounded-md '>
                                    <div className=' absolute right-2 top-4 flex justify-end gap-4 items-end px-6 py-2'>


                                        <div className="bg-gray-200 rounded-full w-6 h-6 flex justify-center items-center" onClick={onClose}><CloseIcon style={{ fontSize: 'small' }} /></div>
                                    </div>
                                    <div className=" flex gap-10  px-10 py-6 shadow-lg">

                                        <div className="w-2/3">
                                            <div className="flex justify-between ">
                                                <div className=" text-xl pb-4 inter-bold">Profile</div>
                                                <div>
                                                    <button
                                                        id="upload_button"
                                                        className="px-4 py-1 inter-font text-white bg-black text-[13px] rounded  focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                    >
                                                        Upload Documents
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex gap-4">
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 w-[200px] text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        NAME
                                                    </label>
                                                    <input
                                                        className=" text-[12px] inter-light  rounded-md border border-gray-300 rounded w-[210px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        id="name"
                                                        name="name"
                                                        value={selectedItemData1?.name}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] w-[210px] font-medium  tracking-widest mb-2"
                                                        htmlFor="phone"
                                                    >
                                                        PHONE NUMBER
                                                    </label>
                                                    <input
                                                        className=" text-[12px] inter-light  rounded-md  border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text"
                                                        id="mobile"
                                                        name="mobile"
                                                        value={selectedItemData1?.mobile}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium tracking-widest "
                                                    htmlFor="degree"
                                                >
                                                    DEGREE
                                                </label>
                                                <Box sx={{ width: '100%' }}>
                                                    <Tabs
                                                        value={selectedDegreeIndex}
                                                        onChange={handleDegreeTabChange}
                                                        aria-label="Degree Tabs"
                                                    >
                                                        {degreeTabs.map((degree, index) => (
                                                            <Tab key={index} label={degree.name}
                                                                sx={{
                                                                    fontSize: '10px',
                                                                    color: 'gray',
                                                                    fontWeight: '500',
                                                                    '&.Mui-selected': {
                                                                        color: 'blue.600', // Change this to the desired color for the selected tab
                                                                    },
                                                                }}

                                                                style={{ fontSize: '10px' }} className='inter-font text-red-600'
                                                            />
                                                        ))}
                                                    </Tabs>
                                                </Box>
                                                <input
                                                    className="text-[12px] hidden inter-light rounded-md border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="degree"
                                                    name="degree"
                                                    value={selectedItemData1?.degree}
                                                    onChange={handleInputChange}
                                                />
                                            </div>

                                            <div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="phone"
                                                    >
                                                        COURSE STUDIED
                                                    </label>
                                                    <input
                                                        className=" text-[12px] inter-light rounded-md  border border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                                                        type="text"
                                                        id="course"
                                                        name="course"
                                                        value={selectedItemData1?.course}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>
                                            </div>
                                            <div className="flex gap-4">
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        PERCENTAGE
                                                    </label>
                                                    <input
                                                        className=" text-[12px] inter-light  rounded-md  border border-gray-300 rounded w-[210px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text"
                                                        id="percentage"
                                                        name="percentage"

                                                        value={selectedItemData1?.percentage}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="phone"
                                                    >
                                                        PASSED YEAR
                                                    </label>
                                                    <input
                                                        className=" text-[12px] inter-light  rounded-md  border border-gray-300 rounded w-[210px] py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        type="text"
                                                        id="passed_year"
                                                        name="passed_year"

                                                        value={selectedItemData1?.passed_year}
                                                        onChange={handleInputChange}

                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4" ref={dropdownRef}>
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium tracking-widest mb-2"

                                                    >
                                                        PREFERRED COUNTRY
                                                    </label>
                                                    <div className={`flex flex-wrap gap-2 border border-gray-300 rounded-md ${selectedItemData1?.preferred_country ? 'py-1' : 'py-4'}`} onClick={handleCountryToggle}>
                                                        {selectedItemData1?.preferred_country && (
                                                            <div className='px-2' >
                                                                <div
                                                                    className="bg-blue-100 px-2 border border-blue-300"

                                                                >
                                                                    <div className='flex items-center '>

                                                                        <CloseIcon style={{ fontSize: '13px', color: '317FF6' }} onClick={handleTagRemove} />

                                                                        <div className='border-l px-1 border-1 border-blue-300 h-6'></div>
                                                                        <div className='text-[12px] text-blue-400 inter-light'>
                                                                            {options.countries.find(city => city.id === selectedItemData1?.preferred_country)?.name}
                                                                        </div>

                                                                    </div>


                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    {isCountryOpen && (
                                                        <ul className="absolute z-10 bg-white border border-gray-200 rounded-md mt-1 max-h-32 overflow-auto">
                                                            {options.countries.map(option => (
                                                                <div
                                                                    key={option.id}
                                                                    className="option"
                                                                    onClick={() => handleCountrySelect(option.id)}
                                                                >
                                                                    <div className='flex-flex-col items-center justify-center gap-2 w-[430px]'>
                                                                        <div className='flex gap-2 items-center hover:bg-gray-100 px-2 cursor-pointer'>
                                                                            <div style={{ backgroundColor: `#${option.bg_color}`, cursor: 'pointer' }} className='w-2 h-2 rounded-full'></div>
                                                                            <div className='py-1 inter-light text-[11px]'>
                                                                                {option.name}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <label
                                                    className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                    htmlFor="about_candidate"
                                                >
                                                    About Candidate
                                                </label>
                                                <textarea
                                                    className="shadow appearance-none  border text-[12px] resize-none inter-light  border-gray-300 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                    placeholder="Add other details about the client details..."
                                                    value={selectedItemData1.description || ''}
                                                    name="description"
                                                    onChange={handleInputChange}
                                                    rows={4}
                                                />
                                            </div>
                                            <div className="inter-bold text-xl pb-2">Activity</div>
                                            <div className=" border border-gray-300  rounded-md py-2 px-4  w-full">
                                                <div className="flex gap-2 items-center">
                                                    <div className="w-10 h-10 rounded-full overflow-hidden">
                                                        <img className="w-full h-full object-cover" src="profile.jpg" alt="Profile" />
                                                    </div>
                                                    <div>
                                                        <span className="text-[11px] font-bold">{userInfo && userInfo.first_name ? userInfo.first_name : userInfo && userInfo.username}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <textarea
                                                        className="  text-[12px] pt-2 inter-light rounded w-full resize-none px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                                        onChange={(e) => setGetComment(e.target.value)}
                                                        value={getComment}

                                                        defaultValue={""}
                                                        placeholder='Enter Comments'
                                                    />
                                                </div>
                                                <div className=" px-4">
                                                    <div className="border-b  border-gray-300 w-full px-8" />
                                                </div>
                                                <div className="flex justify-end pt-2 ">
                                                    <button
                                                        type="submit"
                                                        onClick={commentSubmit}
                                                        className="px-4 py-1.5 font-semibold text-white bg-black text-[13px] rounded  focus:outline-none "
                                                    >
                                                        Comment
                                                    </button>
                                                </div>
                                            </div>
                                            <div>
                                                {showComments.map((comment, id) => (
                                                    <div key={id} className="py-4 flex gap-2">
                                                        <div>
                                                            <div className="w-10 h-10 rounded-full overflow-hidden">
                                                                <img className="w-full h-full object-cover" src="profile.jpg" alt="Profile" />
                                                            </div></div>
                                                        <div className=" border w-[384px] rounded-md bg-blue-100 py-4">
                                                            <div className="flex justify-between px-4 ">
                                                                <div className="text-[10px] font-bold">{comment?.created_by.first_name}</div>
                                                                <div className="text-[10px] text-gray-400">{comment.total_days}Days</div>
                                                            </div>
                                                            <div className="text-[13px] px-4">
                                                                {comment.comment}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            {nextUrl && (
                                                <span
                                                    className="text-[12px] text-gray-500 inter-font cursor-pointer underline"
                                                    onClick={() => fetchComments(false)}
                                                >
                                                    See more comments
                                                </span>
                                            )}
                                        </div>
                                        <div className="w-1/3">
                                            <div className=" text-xl inter-bold">Details</div>
                                            <div className="flex flex-col py-2 ">
                                                <span className=" block text-gray-400 text-[10px] font-medium  tracking-widest mb-1">
                                                    {" "}
                                                    CLIENT ID
                                                </span>
                                                <span className=" text-[11px] font-bold  tracking-widest">{selectedItemData1.client_id}</span>
                                            </div>
                                            <div className="flex flex-col pt-6">
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        STAGE
                                                    </label>
                                                    <StageUpdated
                                                        options={options.stages}
                                                        value={selectedItemData1?.stage ? selectedItemData1.stage.id : ''}
                                                        onChange={(selectedStageId) => {
                                                            const selectedStage = options.stages.find(stage => stage.id === parseInt(selectedStageId));
                                                            handleInputChange({
                                                                target: {
                                                                    name: 'stage',
                                                                    value: selectedStage
                                                                }
                                                            });
                                                        }}
                                                        selectedItemData1={selectedItemData1}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        PRIORITY
                                                    </label>
                                                    <PriorityUpdated
                                                        options={options.priorities}
                                                        toggleListPriority={toggleListPriority}
                                                        value={selectedItemData1?.priority ? selectedItemData1.priority.id : ''}
                                                        onChange={(selectedPriorityId) => {
                                                            const selectedPriority = options.priorities.find(priority => priority.id === selectedPriorityId);
                                                            handleInputChange({
                                                                target: {
                                                                    name: 'priority',
                                                                    value: selectedPriority
                                                                }
                                                            });
                                                        }}
                                                        selectedItemData1={selectedItemData1}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        CATEGORY
                                                    </label>
                                                    <CategoryUpdated
                                                        options={options.categories}
                                                        value={selectedItemData1?.category ? selectedItemData1.category.id : ''}
                                                        onChange={(selectedCategoryId) => {
                                                            const selectedCategory = options.categories.find(category => category.id === parseInt(selectedCategoryId));
                                                            handleInputChange({
                                                                target: {
                                                                    name: 'category',
                                                                    value: selectedCategory
                                                                }
                                                            });
                                                        }}
                                                        selectedItemData1={selectedItemData1}
                                                    />
                                                </div>

                                                {menus && menus.source && (




                                                    <div className="mb-4">
                                                        <label
                                                            className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                            htmlFor="name"
                                                        >
                                                            SOURCE
                                                        </label>
                                                        <SourceUpdated
                                                            options={options.sources}
                                                            value={selectedItemData1?.source || ''}
                                                            onChange={handleInputChange}
                                                            selectedItemData1={selectedItemData1}
                                                        />
                                                    </div>
                                                )}

                                                {menus && menus.assign_agency && (
                                                    <div className="mb-4">
                                                        <label
                                                            className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                            htmlFor="name"
                                                        >
                                                            ASSIGNED TO
                                                        </label>
                                                        <AgencyUpdated
                                                            options={options.agencies}
                                                            value={selectedItemData1?.agency ? selectedItemData1.agency.id : ''}
                                                            onChange={(selectedAgencyId) => {
                                                                const selectedagency = options.agencies.find(agency => agency.id === parseInt(selectedAgencyId));
                                                                handleInputChange({
                                                                    target: {
                                                                        name: 'agency',
                                                                        value: selectedagency
                                                                    }
                                                                });
                                                            }}
                                                            selectedItemData1={selectedItemData1}


                                                        />
                                                    </div>
                                                )}
                                                <div className="mb-4">
                                                    <label
                                                        className="block text-gray-400 text-[10px] font-medium  tracking-widest mb-2"
                                                        htmlFor="name"
                                                    >
                                                        HANDLED BY
                                                    </label>
                                                    <AssigneeUpdated
                                                        options={options.staffs}
                                                        value={selectedItemData1?.assignee ? selectedItemData1.assignee.id : ''}
                                                        onChange={(selectedAssigneeId) => {
                                                            const selectedassignee = options.staffs.find(assignee => assignee.id === parseInt(selectedAssigneeId));
                                                            handleInputChange({
                                                                target: {
                                                                    name: 'assignee',
                                                                    value: selectedassignee
                                                                }
                                                            });
                                                        }}
                                                        selectedItemData1={selectedItemData1}


                                                    />
                                                </div>
                                                <div className="flex flex-col gap-4">
                                                    <div className="pt-16">
                                                        <button

                                                            onClick={handleSubmit}
                                                            className="px-4 py-2 inter-font  w-full text-black bg-green-500 text-sm rounded  focus:outline-none "
                                                        >
                                                            UPDATE
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <button
                                                            id="upload_button"
                                                            className="px-4 py-2  inter-font  w-full text-white bg-black text-sm  rounded  focus:outline-none focus:ring-2 focus:ring-blue-400"
                                                        >
                                                            FOLLOW UP
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>

                        </div>
                    </motion.div>
                </>

            </div>

        </div>
    )
}

export default UpdateStudentForm