
import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';

const validateToken = (token) => {

  if (!token) {
    return false
  }

  let decodedToken = jwtDecode(token);
  
  let currentDate = new Date();


  if (decodedToken.exp * 1000 < currentDate.getTime()) {
   
    return false;
  } else {
   
    return true;
  }
};

const PrivateRoute = () => {

  const token = localStorage.getItem('token');
  const isValid = validateToken(token);

  if (!isValid) {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.clear();

    return <Navigate to="/login" />;
  }

  return <Outlet />;
};


export default PrivateRoute;
