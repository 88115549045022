

import React, { useEffect, useState, useRef } from 'react';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import ListingTable from '../ListingTable/ListingTable';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useNavigate } from 'react-router-dom';
import List from '../List/List';


const Dashboard = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedAgency, setSelectedAgency] = useState(() => {
    const storedAgency = localStorage.getItem('selectedAgency');
    return storedAgency ? JSON.parse(storedAgency) : null;
  });
  const [selectedStage, setSelectedStage] = useState(() => {
    const storedStage = localStorage.getItem('selectedStage');
    return storedStage ? JSON.parse(storedStage) : null;
  });
  const [isOpen, setIsOpen] = useState(false);
  const [clients, setClients] = useState([]);
  const [isOpenStage, setIsOpenStage] = useState(false);
  const [isUserSideVisible, setIsUserSideVisible] = useState(false);
  const [isAgentSideVisible, setIsAgentSideVisible] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [menus, setMenu] = useState(false);
  const [nextUrl, setNextUrl] = useState(null);
  const [showStages, setShowStages] = useState(false);
  

  const handleCancel = () => {
    setShowStages(false);
    
    
};
const handleCancelall =() =>{
  handleCancel()
  handleClearSelection()
  handleStageSelection()

}
const handleClearSelection = () => {
      
  handleAgencySelection(null);
  

};




const fetchClients = async (reset = true) => {
 
  const storedAgency = localStorage.getItem('selectedAgency');
  const agency = storedAgency ? JSON.parse(storedAgency) : selectedAgency;
  const storedStage = localStorage.getItem('selectedStage');
  const stage = storedStage ? JSON.parse(storedStage) : selectedStage;

  let url = 'kanban/api/v1/clients/?limit=20&offset=0';
  if (!reset && nextUrl) {
    url = nextUrl;
  } else {
    url = `${url}&agency=${agency ? agency.id : ''}&stage=${stage ? stage.id : ''}&search=${searchQuery}`;
  }

  try {
    const response = await axiosInstance.get(url);
    
    const newClients = reset ? response.data.results : [...clients, ...response.data.results];
    setClients(newClients);
    setTotalCount(response.data.count);
    setNextUrl(response.data.next);
    setLoading(false);
  } catch (error) {
    console.error('Error fetching clients:', error);
    setError(error);
    setLoading(false);
  }
};



  useEffect(() => {
    if (selectedAgency) {
      localStorage.setItem('selectedAgency', JSON.stringify(selectedAgency));
    } else {
      localStorage.removeItem('selectedAgency');
    }
  }, [selectedAgency]);

  useEffect(() => {
    if (selectedStage) {
      localStorage.setItem('selectedStage', JSON.stringify(selectedStage));
    } else {
      localStorage.removeItem('selectedStage');
    }
  }, [selectedStage]);

  useEffect(() => {
    fetchClients(true);
  }, [selectedAgency, selectedStage, searchQuery]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 && nextUrl) {
        fetchClients(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [nextUrl, loading]);

 const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAgencySelection = (agency) => {
    setSelectedAgency(agency);
    setIsOpen(false);
  };

  const handleStageSelection = (stage) => {
    setSelectedStage(stage);
    setIsOpenStage(false);
  };

  useEffect(() => {
    const fetchIcons = async () => {
      try {
        const response = await axiosInstance.get('account/api/v1/module_menus/');
        setMenu(response.data);
      } catch (error) {
        console.error('Error fetching icons:', error);
        setError(error);
      }
    };

    fetchIcons();
  }, []);

  







  return (
    <div className='relative hidden-scrollbar'>
      <div className='fixed bg-white w-full z-20'>
        <Header
          handleSearchInputChange={handleSearchInputChange}
          handleAgencySelection={handleAgencySelection}
          handleStageSelection={handleStageSelection}
          selectedAgency={selectedAgency}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setIsOpenStage={setIsOpenStage}
          isOpenStage={isOpenStage}
          selectedStage={selectedStage}
          searchQuery={searchQuery}
          menus={menus}
          setSelectedAgency={setSelectedAgency}
          handleCancelall={handleCancelall}
        />
      </div>

      <div className='flex pt-6'>
        <div className='fixed bg-white  pt-[65px]'>
          <SideBar
          
            menus={menus}
          // Add a handler for Layer_3Svg click
          />
        </div>
        <div className='px-4 py-2 absolute  w-full' style={{ paddingTop: '80px', paddingLeft: '90px' }}>
            <List
              handleAgencySelection={handleAgencySelection}
              handleStageSelection={handleStageSelection}
              selectedAgency={selectedAgency}
              selectedStage={selectedStage}
              menus={menus}
              setShowStages={setShowStages}
              showStages={showStages}


            />
            
          </div>
        <div className='flex-grow hidden-scrollbar'>
         
          <div style={{ paddingTop: '140px', paddingLeft: '90px' }} className=''>
            <ListingTable
              menus={menus}
              setClients={setClients}
              clients={clients}
              fetchClients={fetchClients}
              loading={loading}
              setSelectedStage={setSelectedStage}
              setSelectedAgency={setSelectedAgency}
              handleAgencySelection={handleAgencySelection}
              handleStageSelection={handleStageSelection}
              selectedAgency={selectedAgency}
              selectedStage={selectedStage}

            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;












