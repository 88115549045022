import React from 'react'
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'
import CourseListingPage from './CourseListingPage'

const CourseDashboard = () => {
    return (
        <div className='relative'>

            <div>
                <Header/>
            </div>
            <hr />
            <div className='flex pt-2'>
                <div>
                    <SideBar/>
                </div>
                <div className='flex-grow'>
                    <div className='px-4 py-2'>

                    </div>

                    <div className=' '>
                       <CourseListingPage/>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CourseDashboard
