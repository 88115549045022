import React, { useState, useEffect, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const SourceUpdated = ({ options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionId) => {
        onChange({
            target: {
                name: 'source',
                value: optionId
            }
        });
        setIsOpen(false);
    };

    const selectedSource = options.find(option => option.id === value);

    return (
        <div className="custom-dropdown relative " ref={dropdownRef}>
            <div className='flex relative   justify-center'>
                <div
                    className="selected-option border border-gray-100 rounded-md w-full text-center  px-4 text-[11px] py-2 text-gray-600 inter-semilight"
                    style={{ cursor: 'pointer' }}
                    onClick={handleToggle}
                >
                    {selectedSource ? selectedSource.name : 'Select Source'}
                   
                </div>
                <div className='absolute top-2 text-gray-400  right-6'>
                    <ArrowDropDownIcon style={{ position: 'absolute', fontSize: '22px', color: 'blue' }} />


                </div>


            </div>

            {isOpen && (
                <div className="options text-[11px]  py-1 absolute z-30 bg-white w-full max-h-[80px] overflow-y-auto shadow-md font-bold">
                    {options.map(option => (
                        <div
                            key={option.id}
                            className="option hover:bg-gray-100"
                            onClick={() => handleOptionClick(option.id)}
                        >
                            <div className='flex flex-col gap-2'>
                                <div className='flex   gap-2 items-center px-2 cursor-pointer'>
                                    <div className='py-1 inter-light text-[11px]'>
                                        {option.name}
                                    </div>

                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            )}

        </div>
    );
};

export default SourceUpdated;