
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';

const List = ({ handleAgencySelection, handleStageSelection, menus, setShowStages, showStages }) => {
    const [options, setOptions] = useState({
        categories: [],
        stages: [],
        priorities: [],
        agencies: [],
    });
    const [adminOptions, setAdminOptions] = useState({ adminstages: [] });
    const [selectedAgency, setSelectedAgency] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [totalClients, setTotalClients] = useState(0);
    const [totalAgency, setTotalAgency] = useState(0);
    const [userFirstName, setUserFirstName] = useState('');
    const [updatedAgencyFlag, setUpdatedAgencyFlag] = useState(false);
    const fetchAdminOptions = async (id) => {
        try {
            const stagesResponse = await axiosInstance.get(`kanban/api/v1/stage_dropdown/?limit=&search=&agency=${id}`);
            setAdminOptions((prevOptions) => ({
                ...prevOptions,
                adminstages: stagesResponse.data,
            }));
            setShowStages(true);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    const handleAdminAgencySelection = (agency) => {
        setSelectedAgency(agency);
        fetchAdminOptions(agency.id);
        handleAgencySelection(agency);
    };

    const handleCancel = () => {
        setShowStages(false);
    };



    const fetchOptions = async () => {
        try {


            const stagesResponse = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
            setOptions((prevOptions) => ({
                ...prevOptions,
                stages: stagesResponse.data,
            }));



            const agenciesResponse = await axiosInstance.get('kanban/api/v1/agency_dropdown/?limit=&search=');
            setOptions((prevOptions) => ({
                ...prevOptions,
                agencies: agenciesResponse.data,
            }));
            setTotalClients(agenciesResponse.data.length > 0 ? agenciesResponse.data[0].total_clients : 0);
            const userInfo = localStorage.getItem('user_info');

            if (userInfo) {
                const { first_name, agency } = JSON.parse(userInfo);
                setUserFirstName(first_name);


                const matchedAgency = agenciesResponse.data.find(agencyItem => {

                    if (agencyItem.name === first_name) {
                        return true;
                    }

                    if (agency && agencyItem.name === agency.first_name) {
                        return true;
                    }
                    return false;
                });

                if (matchedAgency) {
                    setTotalAgency(matchedAgency.count);
                } else {
                    setTotalAgency(0);
                }
            }


        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        fetchOptions();

        const storedAgency = localStorage.getItem('selectedAgency');
        if (storedAgency) {
            const agency = JSON.parse(storedAgency);
            setSelectedAgency(agency);
            fetchAdminOptions(agency.id);
            setShowStages(true);
        }

        const storedStage = localStorage.getItem('selectedStage');
        if (storedStage) {
            setSelectedStage(JSON.parse(storedStage));
        }
    }, []);
    useEffect(() => {
        const handleClientStageUpdated = () => {
            const updatedClientStage = localStorage.getItem('updated_client_stage');
            const storedAgency = localStorage.getItem('selectedAgency');



            if (updatedClientStage && storedAgency) {
                try {
                    const client = JSON.parse(updatedClientStage);
                    const agency = JSON.parse(storedAgency);
                    console.log('Client from local storage:', client);
                    console.log('Agency from local storage:', agency);


                    if (client && client.agency && client.agency.id && agency && agency.id) {
                        fetchAdminOptions(agency.id);
                    } else {
                        console.error('Invalid client or agency data:', client, agency);
                    }
                } catch (error) {
                    console.error('Error parsing updated_client_stage or selectedAgency:', error);
                }
            }


        };

        window.addEventListener('clientStageUpdated', handleClientStageUpdated);

        return () => {
            window.removeEventListener('clientStageUpdated', handleClientStageUpdated);
        };
    }, [selectedAgency]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'updated_Agency') {
                console.log('updated_Agency detected in local storage');
                fetchOptions();
                localStorage.removeItem('updated_Agency');
            }
        };

        window.addEventListener('storage', handleStorageChange);

        if (localStorage.getItem('updated_Agency')) {
            console.log('updated_Agency found on mount');
            fetchOptions();
            localStorage.removeItem('updated_Agency');
        }

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);






    const handleClearStageSelection = () => {
        handleStageSelection(null);
        setSelectedStage(null);
        localStorage.removeItem('selectedStage');
    };

    const handleStageSelect = (stage) => {
        handleStageSelection(stage);
        setSelectedStage(stage);
        localStorage.setItem('selectedStage', JSON.stringify(stage));
    };
    const isSelectedStageStored = !!localStorage.getItem('selectedStage');
    return (
        <div>
            {menus && menus.consultant && (
                <div className='overflow-x-auto w-full hidden-scrollbar'>
                    <div>
                        {!showStages && (
                            <div className="w-full">
                                {menus.consultant && (
                                    <div className='flex gap-2'>
                                        <div className='flex gap-2'>
                                            <div className='flex gap-2 justify-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-100'>
                                                <span className='inter-font whitespace-nowrap text-center text-black text-[11px]'>{totalClients}</span>
                                                <span className='inter-font whitespace-nowrap text-center text-gray-400 text-[11px]'>Total Clients</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="flex gap-2">
                                                {options.agencies.map((agency) => (
                                                    <div key={agency.name} >
                                                        <button

                                                            className="flex gap-2 justify-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "

                                                            style={{
                                                                backgroundColor: agency.name === 'Unassigned' ? '#e3dee0' : `#${agency.bg_color}`
                                                            }}

                                                            onClick={() => handleAdminAgencySelection(agency)}
                                                        >
                                                            <div className="inter-font whitespace-nowrap text-center text-black text-[11px]">
                                                                {agency.count}
                                                            </div>
                                                            <div className="inter-font whitespace-nowrap text-center text-gray-400 text-[11px]">
                                                                {agency.name}
                                                            </div>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {showStages && (
                            <div className='flex gap-2'>
                                {selectedAgency ? (
                                    <div className='flex gap-2 justify-center  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-100 cursor-pointer' onClick={handleClearStageSelection}>
                                        <span className='inter-font whitespace-nowrap text-center text-black text-[11px]'>{selectedAgency.count}</span>
                                        <span className='inter-font whitespace-nowrap text-center text-gray-400 text-[11px]'>Total Count</span>
                                    </div>
                                ) : (
                                    <div className='flex gap-2 justify-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-100'>
                                        <span className='inter-font whitespace-nowrap text-center text-black text-[11px]'>{totalClients}</span>
                                        <span className='inter-font whitespace-nowrap text-center text-gray-400 text-[11px]'>Total Clients</span>
                                    </div>
                                )}
                                <div className="overflow-x-auto w-full">
                                    <div className="flex gap-2">
                                        {adminOptions.adminstages.map((stage) => (
                                            <div key={stage.name} className="">
                                                <button
                                                    className={`flex gap-2 justify-center items-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isSelectedStageStored && selectedStage.name === stage.name ? 'border-2 border-gray-500' : ''}`}
                                                    style={{ backgroundColor: `#${stage.bg_color}` }}
                                                    onClick={() => handleStageSelect(stage)}
                                                >
                                                    <div className="inter-font whitespace-nowrap text-center text-black text-[10px]">
                                                        {stage.count}
                                                    </div>
                                                    <div className="inter-font whitespace-nowrap text-center text-gray-400 text-[10px]">
                                                        {stage.name}
                                                    </div>
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {menus && menus.assignee && (
                <div className='flex gap-2'>
                    <div className='flex gap-2 justify-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline bg-blue-100 cursor-pointer' onClick={handleClearStageSelection}>
                        <span className='inter-font whitespace-nowrap text-center text-black text-[11px]'>{totalAgency}</span>
                        <span className='inter-font whitespace-nowrap text-center text-gray-400 text-[11px]'>Total Clients</span>
                    </div>
                    <div className="overflow-x-auto w-full">
                        {menus && menus.assignee && (
                            <div className="flex gap-2 ">
                                {options.stages.map((stage) => (
                                    <div key={stage.name} className="">
                                        <button
                                            className={`flex gap-2 justify-center items-center font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isSelectedStageStored && selectedStage.name === stage.name ? 'border-2 border-gray-500' : ''}`}
                                            style={{ backgroundColor: `#${stage.bg_color}` }}
                                            onClick={() => handleStageSelect(stage)

                                            }
                                        >
                                            <div className="inter-font whitespace-nowrap text-center text-black text-[10px]">
                                                {stage.count}
                                            </div>
                                            <div className="inter-font whitespace-nowrap text-center text-gray-400 text-[10px]">
                                                {stage.name}

                                            </div>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                </div>

            )}
        </div>
    );
};

export default List;







