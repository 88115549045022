import React from 'react'
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'
import CountryListingPage from './CountryListingPage'

const CountryDashboard = () => {
    return (
        <div className='relative'>
            <div>
                <Header />
            </div>
            <hr />
            <div className='flex shadow-lg'>
                <div className='pt-2 '>
                    <SideBar />
                </div>
                <div className='flex-grow'>
                    
                    <div className=' '>
                        <CountryListingPage />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CountryDashboard