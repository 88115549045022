

import React, { useState, useEffect, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const AssigneeDropDown = ({ options, value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionId) => {
        onChange(optionId);
        setIsOpen(false);
    };
    const defaultOption = options.find(option => option.name === 'Norvel');
    useEffect(() => {
        // Set default value if value is null
        if (!value && defaultOption) {
            onChange(defaultOption.id);
        }
    }, [defaultOption, onChange, value]);


    return (
        <div className="custom-dropdown  relative" ref={dropdownRef}>
            <div className='flex relative'>
            <div className="selected-option border border-gray-100 rounded-md w-full overflow-y-auto  text-center text-[11px] py-2 text-gray-600 inter-semilight  " style={{ cursor: 'pointer' }} onClick={handleToggle}>
                {value ? options.find(option => option.id === value)?.name : (defaultOption ? defaultOption.name : 'Select Handled By')}
            </div>
            <div className='absolute top-2 text-gray-400  right-6'>
                        <ArrowDropDownIcon style={{ position: 'absolute', fontSize: '22px',color:'blue' }} />


                        </div>



            </div>
            
            {isOpen && (
                <div className="options  text-[11px]  py-1 shadow-md w-full  absolute z-30 bg-white font-bold">
                    {options.map(option => (
                        <div
                            key={option.id}
                            className="option hover:bg-gray-100"

                            onClick={() => handleOptionClick(option.id)}
                        >
                            <div className='flex flex-col   gap-2 rounded-md'>
                                <div className='flex gap-2 items-center px-2 cursor-pointer '>
                                    <div style={{ backgroundColor: `#${option.bg_color}`, cursor: 'pointer' }} className='w-2 h-2 rounded-full'    ></div>
                                    <div className='py-1 inter-light' >
                                        {option.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default AssigneeDropDown;