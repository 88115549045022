
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosInstance/axiosInstance';
import AddUser from '../Forms/AddUser';
import UpdateUser from '../Update/UpdateUser';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AnimatePresence } from 'framer-motion';


const UserListingPage = ({ menus }) => {
    const [isCategoryOpen, setIsCategoryOpen] = useState(false)
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDisignationOpen, setIsDesignationOpen] = useState(false);
    const [isAddUserVisible, setIsAddUserVisible] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    
   
    const [nextUrl, setNextUrl] = useState(null);

    const categoryDropdownRef = useRef(null);
    const destinationDropdownRef = useRef(null);

    const handleClickOutside = (event) => {

        if (
            categoryDropdownRef.current &&
            !categoryDropdownRef.current.contains(event.target)
        ) {
            setIsCategoryOpen(false);
        }
        if (
            destinationDropdownRef.current &&
            !destinationDropdownRef.current.contains(event.target)
        ) {
            setIsDesignationOpen(false);
        }

    };


    useEffect(() => {
        
        document.addEventListener('mousedown', handleClickOutside);

        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);



    const fetchData = async (reset = true) => {

        console.log("fetch data", reset);
        setLoading(true);
        const url = reset ? '/account/api/v1/users/?limit=10&offset=0' : nextUrl;

        try {
            const response = await axiosInstance.get(url);
            const newClients = reset ? response.data.results : [...data, ...response.data.results];
            setData(newClients);
            setNextUrl(response.data.next);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users:', error);
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 1 && nextUrl) {
                fetchData(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [nextUrl, loading]);

    useEffect(() => {
        fetchData();
    }, []);





    useEffect(() => {
        setModalVisible(false);
    }, []);

    const handleCloseModal = () => {
        setSelectedUserData(null);
        setModalVisible(false);
    };

    

    const toggleAddUserVisibility = () => {
        setIsAddUserVisible(!isAddUserVisible);
    };

    const handleClose = () => {
        setIsAddUserVisible(false);
    };

    const toggleListCategory = (itemId) => {
        setIsCategoryOpen(itemId === isCategoryOpen ? null : itemId);
    };

    const toggleListDesignation = (itemId) => {
        setIsDesignationOpen(itemId === isDisignationOpen ? null : itemId);
    };

    const handleCategoryChange = async (categoryID, user) => {
        try {
            console.log("client", user);
            await axiosInstance.patch(`/account/api/v1/user-detail-update/${user.id}/`, { 'category': categoryID });
            console.log('Category updated successfully');
            setIsCategoryOpen(false);
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };

    const handleDesignationChange = async (designationID, user) => {
        try {
            console.log("user", user);
            await axiosInstance.patch(`/account/api/v1/user-detail-update/${user.id}/`, { 'designation': designationID });
            console.log('designation updated successfully');
            setIsDesignationOpen(false);
        } catch (error) {
            console.error('Error updating category:', error);
        }
    };



    const [options, setOptions] = useState({
        categories: [],
        designations: [],
    });

    const handleButtonClick = async (id) => {
        try {
            const response = await axiosInstance.get(`/account/api/v1/user-detail-update/${id}/`);
            setSelectedUserData(response.data);
            console.log(response.data, "oooiiii abhi");
            setModalVisible(true);
        } catch (error) {
            console.error('Error fetching item data:', error);
        }
    };

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                const categoriesResponse = await axiosInstance.get('kanban/api/v1/category_dropdown/?limit=&search=');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    categories: categoriesResponse.data
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        const fetchDesignationOptions = async () => {
            try {
                const designationsResponse = await axiosInstance.get('/kanban/api/v1/designation_dropdown/');
                setOptions(prevOptions => ({
                    ...prevOptions,
                    designations: designationsResponse.data
                }));
            } catch (error) {
                console.error('Error fetching options:', error);
            }
        };

        fetchOptions();
        fetchDesignationOptions();



    }, []);

    return (
        <div className='flex flex-col  gap-6 items-center relative '>
            <div>
                <div className="bg-gray-50 rounded-md flex justify-center">
                    <div className="inter-font px-4 flex gap-20 whitespace-nowrap py-4 text-center text-[11px] text-gray-400 w-full" style={{ borderBottom: '1px solid rgba(128, 128, 128, 0.5)', width: '100%' }}>
                        <div className='text-black'>User</div>


                        <div>
                            Source
                        </div>


                       



                        <div>Department</div>
                        <div>Stages</div>
                    </div>
                </div>
            </div>



            <div className='overflow-x-auto flex-grow h-[300px] '>
                <table className="w-full divide-y divide-gray-200 bg-gray-50  ">
                    <thead>
                        <tr>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">#</th>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Created at</th>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Name</th>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Email ID</th>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Phone Number</th>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Designation</th>
                            <th className="inter-font flex justify-center items-center whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400 ">
                                Category
                                <span ><ArrowDropDownIcon style={{ fontSize: '20px', color: 'black' }} /></span>
                            </th>
                            <th className="inter-font whitespace-nowrap px-4 py-3 text-center text-[11px] text-gray-400">Visibility</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index} className="border-b-2 border-gray-100 bg-white">
                                <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">{index + 1}</td>
                                <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">{item.created_at}</td>
                                <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">{item.full_name}</td>
                                <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">{item.email}</td>
                                <td className="inter-light  whitespace-nowrap px-4 py-3 text-center text-[12px] text-black  tracking-wider ">{item.phone}</td>
                                <td className='relative'>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={() => toggleListDesignation(item.id)}
                                            style={{
                                                borderRadius: '60px', fontSize: '11px', display: 'flex', alignItems: "center", justifyContent: "center", width: '70px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                            }}
                                        >
                                            <div className='inter-light' style={{ color: 'black', fontSize: '12px' }}>
                                                {item.designation ? item.designation.name : "Select designation"}
                                            </div>
                                        </button>
                                        {isDisignationOpen === item.id && (
                                            <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={destinationDropdownRef}>
                                                <ul className="py-2">
                                                    {loading ? (
                                                        <li>Loading...</li>
                                                    ) : error ? (
                                                        <li>Error: {error.message}</li>
                                                    ) : (
                                                        options.designations.map((designation) => (
                                                            <li
                                                                key={designation.id}
                                                                className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                onClick={() => handleDesignationChange(designation.id, item)}
                                                            >
                                                                <div className='flex justify-start items-center gap-1'>
                                                                    <div style={{ width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                    <div className='inter-font'>{designation.name}</div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td className='relative'>
                                    <div className="flex justify-center">
                                        <button
                                            onClick={() => toggleListCategory(item.id)}
                                            style={{
                                                backgroundColor: `#${item.category?.bg_color}`, borderRadius: '60px', fontSize: '12px', display: 'flex', alignItems: "center", justifyContent: "center", width: '90px', paddingBottom: '0.5px', paddingTop: '0.5px'
                                            }}
                                        >
                                            <div className='inter-light' style={{ color: `#${item.category?.color_code}`, fontSize: '12px' }}>
                                                {item.category ? item.category.name : "Select Category"}
                                            </div>
                                        </button>
                                        {isCategoryOpen === item.id && (
                                            <div className="absolute mt-6 bg-white rounded-lg shadow-lg z-10 whitespace-nowrap" style={{ width: '90px' }} ref={categoryDropdownRef}>
                                                <ul className="py-2">
                                                    {loading ? (
                                                        <li>Loading...</li>
                                                    ) : error ? (
                                                        <li>Error: {error.message}</li>
                                                    ) : (
                                                        options.categories.map((category) => (
                                                            <li
                                                                key={category.id}
                                                                className="px-2 py-2 text-[11px] hover:bg-gray-100 cursor-pointer"
                                                                onClick={() => handleCategoryChange(category.id, item)}
                                                            >
                                                                <div className='flex justify-start items-center gap-1'>
                                                                    <div style={{ backgroundColor: `#${category.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                    <div className='inter-font'>{category.name}</div>
                                                                </div>
                                                            </li>
                                                        ))
                                                    )}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </td>
                                <td className="inter-font whitespace-nowrap px-4 py-3 text-center text-[10px] text-black tracking-wider font-semibold">
                                    <button
                                        type="button"
                                        onClick={() => handleButtonClick(item.id)}
                                        className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 text-[10px] px-16 py-1 text-center dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                                    >
                                        Edit
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            <div className='flex justify-center items-center inter-font '>
                <button
                    type="button"
                    onClick={toggleAddUserVisibility}
                    className="text-black-600 bg-black text-white border border-black-700 hover:bg-black-800 focus:outline-none focus:ring-black-300 rounded-md text-sm px-5 py-2 text-center mb-2 dark:border-black-500 dark:hover:text-white dark:focus:ring-black-800"
                >
                    Add Associate/Staff
                </button>
            </div>
            <AnimatePresence>

                {isAddUserVisible && (
                    <div className="inset-0 z-50 flex items-center justify-center">
                        <AddUser onClose={handleClose} setIsAddUserVisible={setIsAddUserVisible} handleButtonClick={handleButtonClick} fetchData={fetchData} />

                    </div>
                )}
            </AnimatePresence>

            <AnimatePresence>
                {modalVisible && (
                    <div className="inset-0 z-50 flex items-center justify-center ">
                        <UpdateUser selectedUserData={selectedUserData} setSelectedUserData={setSelectedUserData} onClose={handleCloseModal} setModalVisible={setModalVisible} fetchData={fetchData} />
                    </div>
                )}
            </AnimatePresence>

        </div>
    );
};

export default UserListingPage;
