import React, { useState, useEffect, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PriorityDropDown = ({ options, value, onChange, toggleListPriority, selectedStageId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    const handleToggle = () => {
        toggleListPriority(selectedStageId);
        setIsOpen(!isOpen);
        
    };

    const handleOptionClick = (optionId) => {
        const event = {
            target: {
                name: 'priority',
                value: optionId
            }
        };
        console.log('Simulated event object:', event);
        onChange(event);
        setIsOpen(false);
    };

    const defaultOption = options.find(option => option.name === 'Warm');

    const selectedOption = options.find(option => option.id === value);


    return (
        <div className="custom-dropdown relative" ref={dropdownRef}>
            <div className="selected-option border border-gray-100 rounded-md w-full overflow-y-auto text-center text-[11px] py-2 text-gray-600 inter-semilight" style={{ cursor: 'pointer' }} onClick={handleToggle}>
                {selectedOption ? (
                    <div className="flex items-center justify-center relative">
                        <img src={selectedOption.icon} alt={selectedOption.name} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                        <span className='text-[11px]'> {selectedOption.name}</span>
                        <div className='absolute bottom-5 text-gray-400  right-6'>
                        <ArrowDropDownIcon style={{ position: 'absolute', fontSize: '22px',color:'blue' }} />


                        </div>

                    </div>

                ) :

                    (<div className="flex items-center justify-center relative">
                        <img src={defaultOption ? defaultOption.icon : ''} alt={defaultOption ? defaultOption.name : 'Default'} style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                        <span className='text-[11px] '>{defaultOption ? defaultOption.name : ''}</span>
                        <div className='absolute bottom-5 text-gray-400  right-6'>
                        <ArrowDropDownIcon style={{ position: 'absolute', fontSize: '22px',color:'blue' }} />


                        </div>

                    </div>

                    )}
            </div>
            {isOpen && (
                <div className="options text-[11px]  py-1 w-full h-auto overflow-y-auto  shadow-md absolute z-30 bg-white font-bold">
                    {options.map(option => (
                        <div
                            key={option.id}
                            className="option flex items-center  cursor-pointer py-1 hover:bg-gray-100  "
                            onClick={() => handleOptionClick(option.id)}

                        >
                            <div className='flex px-2'>
                            <img src={option.icon} alt={option.name} style={{ width: '20px', height: '20px' }} />
                            <span className='inter-light'>{option.name}</span>

                            </div>


                           

                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PriorityDropDown;






