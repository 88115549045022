import React, { useState, useEffect, useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const PriorityUpdated = ({ options, value, onChange, selectedItemData1, toggleListPriority }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        if (selectedItemData1?.stage) {
            toggleListPriority(selectedItemData1.stage.id);
        }
    }, [selectedItemData1?.stage]);

    const handleToggle = () => {
        if (!isOpen && selectedItemData1?.stage) {
            toggleListPriority(selectedItemData1.stage.id);
        }
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (optionId) => {
        onChange(optionId);
        setIsOpen(false);
    };

    return (
        <div className="custom-dropdown relative" ref={dropdownRef}>
            <div
                className="selected-option border border-gray-100 rounded-md w-full  overflow-y-auto text-[11px] py-2  text-gray-600 inter-semilight"
                style={{ cursor: 'pointer', backgroundColor: value ? `#${options.find(option => option.id === value)?.bg_color}` : '#ffffff' }}
                onClick={handleToggle}
            >
                <div className='flex flex-row gap-2 justify-center items-center px-2 whitespace-nowrap'>
                <div><img src={selectedItemData1?.priority.icon} alt={selectedItemData1?.priority.name} style={{ width: '15px', height: '15px' }} /></div>
                    <div className='text-[11px]' >{selectedItemData1?.priority ? selectedItemData1?.priority.name : 'Select priority'}</div>
                    


                </div>
                <div className='absolute top-2 text-gray-400  right-6'>
                    <ArrowDropDownIcon style={{ position: 'absolute', fontSize: '22px', color: 'blue' }} />


                </div>

            </div>
            {isOpen && (
                <div className="options text-[11px]  py-1 absolute z-30 bg-white w-full shadow-md font-bold ">
                    {options.map(option => (
                        <div
                            key={option.id}
                            className="option hover:bg-gray-100"
                            onClick={() => handleOptionClick(option.id)}
                        >
                            <div className='flex flex-col gap-2'>
                                <div className='flex gap-2 items-center '>

                                    <div className='py-1 inter-light flex items-center px-2  cursor-pointer'>
                                        <img src={option.icon} alt={option.name} style={{ width: '15px', height: '15px' }} />
                                        <span className='text-[11px]'>{option.name}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default PriorityUpdated;
