
import React, { useState, useEffect, useRef } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import axiosInstance from '../axiosInstance/axiosInstance';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { AnimatePresence } from 'framer-motion';
import Logout from '../Login/Logout';
import { useLocation } from 'react-router-dom';
import AddStudent from '../Forms/AddStudent';

const Header = ({ isOpenStage, setIsOpenStage, handleSearchInputChange, handleAgencySelection, handleStageSelection, selectedAgency, isOpen,setIsOpen, selectedStage, searchQuery, menus, handleCancelall }) => {
    const [isAddClientVisible, setIsAddClientVisible] = useState(false);
    const [agencies, setAgencies] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [stages, setStages] = useState([]);
    const [showClearIcon, setShowClearIcon] = useState(false);
     const [showStageIcon, setShowStageIcon] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false)
    const [totalClients, setTotalClients] = useState(0);

    useEffect(() => {
        fetchAgencies();
        fetchStages();
    }, []);



    const dropdownAgencyRef = useRef(null);
    const dropdownStageRef = useRef(null);
    const dropdownProfileRef = useRef(null);

    const handleClickOutside = (event) => {
        if (dropdownAgencyRef.current && !dropdownAgencyRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        if (dropdownStageRef.current && !dropdownStageRef.current.contains(event.target)) {
            setIsOpenStage(false);
        }
        if (dropdownProfileRef.current && !dropdownProfileRef.current.contains(event.target)) {
            setProfileOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const userInfoString = localStorage.getItem('user_info');
    const userInfo = JSON.parse(userInfoString);

    const fetchAgencies = async () => {
        try {
            const response = await axiosInstance.get('kanban/api/v1/agency_dropdown/?limit=&search=');
            setAgencies(response.data);

            setTotalClients(response.data.length > 0 ? response.data[0].total_clients : 0);
            setLoading(false);

        } catch (error) {
            console.error('Error fetching agencies:', error);
            setError(error);
            setLoading(false);
        }
    };

    const fetchStages = async () => {
        try {
            const response = await axiosInstance.get('kanban/api/v1/stage_dropdown/?limit=&search=');
            setStages(response.data);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching stages:', error);
            setError(error);
            setLoading(false);
        }
    };





    const toggleDropdown = () => {

        setIsOpen(!isOpen);
        setIsOpenStage(false);
        setProfileOpen(false)
    };

    const toggleDropdowStage = () => {
        setIsOpenStage(!isOpenStage);
        setIsOpen(false);
        setProfileOpen(false)
    };
    const toggleDropdownProfile = () => {
        setProfileOpen(!profileOpen)
        setIsOpen(false);
        setIsOpenStage(false);


    }

    const toggleAddClientVisibility = () => {
        setIsAddClientVisible(!isAddClientVisible);
    };

    const handleClose = () => {
        setIsAddClientVisible(false);
    };


    const handleClearStageSelection = () => {
        if (isOpen) {
            handleStageSelection(null);

        }
        handleStageSelection(null);

        setShowStageIcon(false);
        setIsOpen(false)

    };

    useEffect(() => {

        if (selectedAgency) {
            setShowClearIcon(true);
        } else {
            setShowClearIcon(false);
        }
    }, [selectedAgency]);
    useEffect(() => {

        if (selectedStage) {
            setShowStageIcon(true);
        } else {
            setShowStageIcon(false);
        }
    }, [selectedStage]);

    const location = useLocation();
    const showSearch = location.pathname !== '/userside';
    const showstaff = location.pathname !== '/agentstaffside';
    const showSource = location.pathname !== '/SourceDashboard';
    const hideSerach = location.pathname !== '/countrydashboard';
    const hideSearchBar = location.pathname !== '/universitydashboard';
    const hideCourse=location.pathname !=='/courseDashboard';
   


    return (
        <div className=''>
            <div className="flex flex-row justify-between items-center px-4 pb-6 pt-4">
                <div className='flex gap-12'>
                    <div className="mb-4 md:mb-0">
                        <div className='flex gap-10 items-center justify-center cursor-pointer' onClick={handleCancelall}>
                            <div className='w-6 h-6 ml-2 overflow-hidden flex justify-center items-center'>
                                <img className='w-full h-full object-cover' src="./Group.png" alt="logo" />
                            </div>
                            <div>
                                <span className="poppins-extrabold text-2xl xl:text-3xl font-bold">Dashboard</span>
                            </div>
                        </div>
                    </div>
                    {showSearch && showstaff && showSource && hideSerach && hideSearchBar && hideCourse && (
                        <div className="flex flex-wrap items-center gap-2">
                            <div className="relative">
                                <span className="absolute inset-y-0 left-0 pl-3 text-green-400 text-sm flex items-center">
                                    <SearchIcon />
                                </span>
                                <input
                                    type="text"
                                    className="inter-font block w-96 text-[12px] rounded-md pr-12 pl-10 pr-3 py-2.5 bg-gray-100 border-gray-200 border-none placeholder-gray-400 focus:outline-none tracking-wide"
                                    placeholder={searchQuery ? searchQuery : "Search here... "}
                                    onChange={handleSearchInputChange}
                                />
                            </div>


                            {/* {menus.agency && (
                        <div className="relative inline-block text-left" ref={dropdownAgencyRef}>
                            <div className="relative flex gap-2">
                            {!menus.consultant && (
                                 <button
                                 onClick={toggleDropdown}
                                 className="bg-gray-100 text-gray-700  font-semibold w-44 py-1.5 px-4 rounded inline-flex flex   items-center gap-2 "
                             >

                                 <div className='flex  items-center g'>
                                     <span className='inter-font text-gray-400 text-[11px] '>
                                         {selectedAgency ? (
                                             <div className='flex items-center gap-8 whitespace-nowrap'>
                                                 <div className='flex gap-2 py-1'>
                                                     <div>{selectedAgency.name}</div>
                                                     <div className='text-[12px] inter-font bg-red-200 text-red-700 rounded-full px-4 '>
                                                         {selectedAgency.count}
                                                     </div>
                                                 </div>
                                                

                                             </div>
                                         ) : (
                                             <div className='flex justify-center gap-2 items-center'>
                                                 <div className='inter-font text-[11px] cursor-pointer'>All Clients</div>

                                                 <div className='text-[12px] inter-font bg-red-200 text-red-700 rounded-full px-4'>
                                                     {totalClients}
                                                 </div>

                                                 <div className='text-black'>
                                                     <ArrowDropDownIcon />
                                                 </div>
                                             </div>
                                         )}
                                     </span>

                                 </div>
                             </button>
                            )}
                               
                               
                                {isOpen && (
                                    <div className="absolute z-10 mt-10  bg-white w-44 rounded-lg shadow-lg" style={{ scrollbarWidth: 'thin' }}>


                                        <ul className="max-h-40 overflow-y-auto">
                                            {loading ? (
                                                <li>Loading...</li>
                                            ) : error ? (
                                                <li>Error: {error.message}</li>
                                            ) : (
                                                agencies.map((agency, index) => (
                                                    <li key={index} className=" py-2 text-[12px] hover:bg-gray-100 cursor-pointer" onClick={() => handleAgencySelection(agency)}>

                                                        <div className='flex px-2 justify-between items-center gap-4'>
                                                            <div className='flex gap-1 items-center'>
                                                                <div style={{ backgroundColor: 'gray', width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                                <div className='font-medium '>{agency.name}</div>



                                                            </div>

                                                            <div className='text-[12px] inter-font bg-red-200 text-red-700 rounded-full px-4  '>{agency.count}</div>
                                                        </div>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>

                    )} */}
                            {/* <div className="relative inline-block text-left" ref={dropdownStageRef}>
                            <div className="relative flex items-center gap-2">
                                {menus && !menus.assignee && (
                                    <button
                                        onClick={toggleDropdowStage}

                                        className="bg-gray-100 text-gray-700 font-semibold w-40 py-1 px-4 rounded inline-flex items-center justify-center"
                                    >



                                        <div className='flex  items-center g'>
                                            <span className='inter-font text-gray-400 text-[11px] '>
                                                {selectedStage ? (
                                                    <div className='flex items-center gap-8 whitespace-nowrap'>
                                                        <div className='flex gap-2 py-1.5'>
                                                            <div>{selectedStage.name}</div>

                                                        </div>


                                                    </div>
                                                ) : (
                                                    <div className='flex justify-center gap-2  items-center'>
                                                        <div className='inter-font text-[11px] py-1.5 cursor-pointer'>All Stages</div>


                                                        <div className='text-black'>
                                                            <ArrowDropDownIcon />
                                                        </div>
                                                    </div>
                                                )}
                                            </span>

                                        </div>
                                    </button>
                                )}

                                {isOpenStage && (
                                    <div className="absolute z-10 mt-60 w-40 bg-white rounded-lg shadow-lg" style={{ scrollbarWidth: 'thin' }}>

                                        <ul className="py-2 max-h-40 overflow-y-auto">
                                            {loading ? (
                                                <li>Loading...</li>
                                            ) : error ? (
                                                <li>Error: {error.message}</li>
                                            ) : (
                                                stages.map((stage, index) => (
                                                    <li key={index} className="px-2 py-2 text-[12px] hover:bg-gray-100 cursor-pointer" onClick={() => handleStageSelection(stage)}>
                                                        <div className='flex justify-start items-center gap-1'>
                                                            <div style={{ backgroundColor: `#${stage.bg_color}`, width: '10px', height: '10px', borderRadius: '80px' }}></div>
                                                            <div className='font-medium'>{stage.name}</div>
                                                            <div style={{ backgroundColor: `#${stage.bg_color}`}} className='text-[10px] px-2 rounded-full py-1 font-medium'>{stage.count}</div>
                                                        </div>
                                                    </li>
                                                ))
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div> */}
                            <button
                                type="button"
                                onClick={toggleAddClientVisibility}
                                className="inter-font bg-black text-white text-sm font-bold px-4 py-2 rounded-md hover:bg-gray-900 focus:outline-none focus:bg-gray-900"
                            >
                                Add Client
                            </button>
                            {/* {menus && (menus.consultant || menus.assignee) && (
                            <div>
                                {showStageIcon ? (
                                    <div className='flex items-center bg-red-100 px-4 py-2 rounded-md cursor-pointer' onClick={handleClearStageSelection}>
                                        <span className='text-red-500 text-[12px] inter-font'> Clear Stage</span>
                                        <CloseIcon style={{ fontSize: '16px', color: 'red' }} />



                                    </div>

                                ) : (
                                    <div></div>

                                )}
                            </div>

                        )} */}

                            {/* <div>
                            {showClearIcon ? (
                                <div className='flex items-center bg-red-100 px-2 py-2 rounded-md cursor-pointer' onClick={handleClearSelection}>
                                    <span className='text-red-500 text-[12px] inter-font'>Clear Agency</span>
                                    <CloseIcon style={{ fontSize: '16px', color: 'red' }} />

                                </div>
                             ) : (
                                <div>


                                </div>
                            )}
                        </div> */}
                        </div>
                    )}

                </div>




                <div className="flex items-center justify-center relative" ref={dropdownProfileRef}>
                    <div className="flex flex-col items-center">
                        <span className="inter-font text-[12px] font-semibold text-black opacity-80">
                            {userInfo && userInfo.first_name ? userInfo.first_name : userInfo && userInfo.username}
                        </span>
                        <span className="inter-font text-[11px] font-semibold text-gray-400">
                            Admin
                        </span>
                    </div>
                    <div className='flex' onClick={toggleDropdownProfile}>
                        <div className="w-10 h-10 ml-2">
                            <img className="w-full h-full rounded-full" src="/profile.jpg" alt="Profile" />
                        </div>
                        <div>
                            <KeyboardArrowDownIcon style={{ fontSize: '20px', color: 'gray' }} />
                        </div>
                    </div>

                    <div className='absolute top-10'>
                        {
                            profileOpen && (
                                <div className='bg-white rounded-md shadow-lg px-8 py-2'>
                                    <Logout />

                                </div>
                            )

                        }
                    </div>

                </div>
            </div>
            <hr></hr>
            <div className='absolute z-10 right-10 top-0 left-10'>
                <AnimatePresence>
                    {/* {isAddClientVisible && <AddClients menus={menus} onClose={handleClose} setIsAddClientVisible={setIsAddClientVisible} />} */}
                    {isAddClientVisible && <AddStudent  menus={menus} onClose={handleClose} setIsAddClientVisible={setIsAddClientVisible} />}
                </AnimatePresence>
            </div>
        </div>
    );
};

export default Header;

