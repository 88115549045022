import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Layer_1Svg } from '../../Assets/Layer_1.svg';
import { ReactComponent as Layer_2Svg } from '../../Assets/Layer_2.svg';
import { ReactComponent as Layer_3Svg } from '../../Assets/Layer_3.svg';
import { ReactComponent as Layer_4Svg } from '../../Assets/Layer_4.svg';
import { ReactComponent as Layer_5Svg } from '../../Assets/Layer_5.svg';
import { ReactComponent as Layer_6Svg } from '../../Assets/Layer_6.svg';
import { ReactComponent as Layer_7Svg } from '../../Assets/Layer_7.svg';
import { ReactComponent as Green_1Svg } from '../../Assets/green_1.svg';
import { ReactComponent as Green_2Svg } from '../../Assets/green_2.svg';
import { ReactComponent as Green_3Svg } from '../../Assets/green_3.svg';
import { ReactComponent as Green_4Svg } from '../../Assets/green_4.svg';
import { ReactComponent as Green_5Svg } from '../../Assets/green_5.svg'

const SideBar = ({ toggleUserSideVisibility, toggleAgentSideVisibility, menus }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const userFromLocalStorage = JSON.parse(localStorage.getItem('user_info'));
  const isUserSideVisible = localStorage.getItem('isUserSideVisible') === 'true';
  const [isLayer7Clicked, setIsLayer7Clicked] = useState(false);
  const [showComponentB, setShowComponentB] = useState(false);

  const handleIconClick = () => {
    navigate("/userside");
  };

  const handleIconBack = () => {
    navigate("/dashboard");
  };

  const handleAgentStaff = () => {
    navigate("/agentstaffside");
  };

  const handleSource = () => {
    navigate("/sourceDashboard");
  };

  const handleCourse = () => {
    navigate("/courseDashboard");
  };

  const handleCountry = () => {
    navigate("/countrydashboard");
  };

  const handleUniversity = () => {
    navigate("/universitydashboard");
  };

  return (
    <div className="py-4 px-6 h-full relative" style={{ height: '85vh' }}>
      <div className="flex flex-col items-center h-full">
        <div className="flex flex-col gap-6">
          <div className="text-green-500 text-xl cursor-pointer">
            <Layer_1Svg />
          </div>
          <div className="text-gray-500 text-xl cursor-pointer" onClick={handleIconBack}>
            {location.pathname === "/dashboard" ? <Green_1Svg /> : <Layer_3Svg />}
          </div>
          <div className="text-gray-500 text-xl cursor-pointer" onClick={handleCourse}>
            {location.pathname === "/courseDashboard" ? <Green_3Svg /> : <Layer_4Svg />}
          </div>
          {menus && menus.assignee ? (
            <>
              

              <div className="text-gray-500 text-xl cursor-pointer">
                <Layer_5Svg />
              </div>
              <div className="text-gray-500 text-xl cursor-pointer">
                <Layer_6Svg />
              </div>
            </>
          ) : (
            <>
            <div className="text-gray-500 text-xl cursor-pointer" onClick={handleCountry}>
                {location.pathname === "/countrydashboard" ? <Green_4Svg /> : <Layer_5Svg />}
              </div>
              <div className="text-gray-500 text-xl cursor-pointer" onClick={handleUniversity}>
                {location.pathname === "/universitydashboard" ? <Green_5Svg /> : <Layer_6Svg />}
              </div>
             
            </>
          )}
        </div>
      </div>

      {menus && menus.settings && (
        <div className="absolute bottom-20 right-6 text-gray-500 text-xl cursor-pointer">
          {location.pathname === "/userside" ? <Green_2Svg /> : <Layer_7Svg fill="blue" onClick={handleIconClick} />}
        </div>
      )}
      {menus && menus.add_staff && (
        <div className="absolute bottom-20 right-6 text-gray-500 text-xl cursor-pointer">
          {(location.pathname === "/agentstaffside") || (location.pathname === "/sourceDashboard")
            ? <Green_2Svg />
            : <Layer_7Svg fill="blue" onClick={handleAgentStaff} />}
        </div>
      )}
    </div>
  );
};

export default SideBar;

