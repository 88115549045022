import React, { useState } from 'react';
import axios from 'axios';
import axiosInstance from '../axiosInstance/axiosInstance';
import { useNavigate } from 'react-router-dom';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Login = () => {
        const navigate = useNavigate();
        const [passwordVisible, setPasswordVisible] = useState(false);

        const togglePasswordVisibility = () => {
            setPasswordVisible(!passwordVisible);
        };
        const [formData, setFormData] = useState({
            username: '',
            password: ''
        });

        const [errorMessage, setErrorMessage] = useState('');

        const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
        };

        const handleSubmit = async (e) => {
            e.preventDefault();
            try {
                const response = await axiosInstance.post('/account/api/v1/login/', formData, {
                    // Include credentials
                });
                if (response && response.data) {
                    const { token, refresh, data } = response.data;
                    localStorage.setItem('token', token);
                    localStorage.setItem('refreshToken', refresh);
                    localStorage.setItem('user_info', JSON.stringify(data));
                
                    if (data.first_login) {
                        console.log("Navigating to password reset");
                        navigate('/password-reset', { state: { username: formData.username, oldpassword: formData.password } });
                    } else if (data.is_superuser) {
                        navigate('/dashboard');
                    } else if (data.is_agency) {
                        navigate('/dashboard');
                    } else {
                        navigate('/dashboard');
                    }


                } else {
                    console.error('Login failed: Response data is missing');
                }
            } catch (error) {
                console.error('Login failed:', error.message);
                setErrorMessage('Something went wrong. Please try again.');
            }
        };

    return (


        <div>
            {/* second section */}
            <section>
                <div>
                    <div className="px-16 ">
                        <div className="flex justify-between items-center pt-4">
                            <div className='w-25 h-10  object-cover'>
                                <img
                                    className="w-full h-full object-cover rounded-lg"
                                    src="./logo.png"
                                    alt="Placeholder Image"
                                />
                            </div>

                            <div className="flex gap-6 text-sm  poppins-medium  whitespace-nowrap  ">
                                <div>About us</div>
                                <div>Privacy policy</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col md:flex-row  flex justify-center items-center h-screen gap-40">
                        <div>
                            <div className="text-3xl font-bold poppins-extrabold">Dedicated Dashboard for <br></br> Partners</div>
                            <div className="flex flex-col gap-4  pt-6">
                                {/* Username Input */}
                                <div>
                                    <input
                                        id="username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        placeholder="UserID"
                                        className="rounded-md border-gray-500 border-2 px-4 text-sm py-1.5 focus:outline-none focus:border-blue-500  w-96 "
                                    />
                                </div>
                                {/* Password Input */}
                                <div className='relative '>
                                    {" "}
                                    <input
                                       type={passwordVisible ? 'text' : 'password'}
                                        id="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="password"
                                        className="    rounded-md border-gray-500 border-2 px-4 py-1.5 text-sm focus:outline-none focus:border-blue-500 w-96"
                                    />
                                    <div className='absolute top-1 right-6  cursor-pointer' onClick={togglePasswordVisibility}>
                                        {passwordVisible ? (
                                            <RemoveRedEyeIcon style={{ fontSize: '18px' }} />
                                            
                                        ) : (
                                            <VisibilityOffIcon style={{ fontSize: '18px' }} />
                                            
                                        )}
                                    </div>
                                </div>

                                <span className=" poppins-medium text-sm flex justify-end items-end">
                                    Forgot Password
                                </span>
                                {/* <button className="btn-31 w-36"  onClick={handleSubmit}>
                                    <span className="text-container">
                                        <span className="text">Login</span>
                                    </span>
                                </button> */}
                                <button className="bg-black text-white py-1.5 w-full md:w-28 rounded-md font-bold" onClick={handleSubmit}>
                                    <span className="text-container">
                                        <span className="text">Log in</span>
                                    </span>
                                </button>
                                {errorMessage && <p className="text-red-500 text-[14px]">{errorMessage}</p>}
                            </div>
                        </div>
                        <div className=" hidden w-96 h-90 md:hidden lg:block">
                            <img
                                className="w-full h-full object-cover "
                                src="./login.png"
                                alt="Placeholder Image"
                            />
                        </div>
                    </div>
                </div>

            </section>
        </div>
    );
};

export default Login;