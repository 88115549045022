import React from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

const CountryDropdown = ({options, selectedOption, onSelect,toggleDropdown,setCountryOpen,  countryOpen}) => {
    const handleSelect = (option) => {
        onSelect(option);
        setCountryOpen(false);
      };
  return (
    <div>
        <div className="relative">
        <div className="relative inline-block w-full">
          <div
            className="border border-gray-300 rounded-md w-full md:w-[200px]  lg:w-[300px] text-[12px] py-2 px-4 text-gray-700 leading-tight flex justify-between items-center outline-none"
            onClick={toggleDropdown}
          >
           {selectedOption ? selectedOption : "Select country"}
            <div> {countryOpen ? <FiChevronUp /> : <FiChevronDown />}</div>

          </div>
          {countryOpen && (
            <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-400 rounded-md shadow-lg">
              {options.map((option, index) => (
                <li
                key={option.id}
                  className="px-4 py-2 cursor-pointer hover:bg-black hover:text-white text-sm"
                  onClick={() => handleSelect(option)}
                >
                  <div className='text-[12px]'>{option.name}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>





    </div>
  )
}

export default CountryDropdown