import React from 'react'
import UniversityListingPage from './universityListingPage'
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'

const UniverstityDashboard = () => {
  return (
    <div>
        <div className='relative'>
            <div>
                <Header />
            </div>
            <hr />
            <div className='flex pt-2 shadow-lg'>
                <div>
                    <SideBar />
                </div>
                <div className='flex-grow'>
                    <div className='px-4 py-2'>
                    </div>

                    <div className='flex justify-center '>
                        <UniversityListingPage/>
                    </div>
                </div>
            </div>
        </div>





    </div>
  )
}

export default UniverstityDashboard