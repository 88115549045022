
import React from 'react';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import PrivateRoute from './Components/PrivateRoute/PrivateRoute';
import PublicRoute from './Components/PublicRoute/PublicRoute';
import Login from './Components/Login/Login';
import ResetPassword from './Components/ResetPassword/ResetPassword';
import Dashboard from './Components/Dashbord/Dashbord';
import UserSide from './Components/UserSide/UserSide';
import AgentStaffSide from './Components/AgentStaff/AgentStaffSide';
import StaffDashboard from './Components/StaffDashboard/StaffDashboard';
import SourceDashboard from './Components/Source/SourceDashboard';
import BasicDetails from './Components/Leades/BasicDetails';
import AddStudent from './Components/Forms/AddStudent';
import UpdateStudentForm from './Components/UpdatedClientsForm/UpdateStudentForm';
import AddCourse from './Components/coursesDetails/AddCourse';
import AddCountry from './Components/CountryDetails/AddCountry';
import CountryListingPage from './Components/CountryDetails/CountryListingPage';
import AddUniversity from './Components/UniversityDetails/AddUniversity';
import CountryDashboard from './Components/CountryDetails/CountryDashboard';
import UniverstityDashboard from './Components/UniversityDetails/UniverstityDashboard';
import CourseDashboard from './Components/coursesDetails/CourseDashboard';





function App() {


  return (
    <div>
      <Router>


        <Routes>
          <Route element={<PublicRoute />}>
            <Route path="" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password-reset" element={<ResetPassword />} />
            <Route path="/studyabroad-enquiryform" element={<BasicDetails />} />
          
            <Route path="/addcountry" element={<AddCountry/>} />
            <Route path="/CountryListing" element={<CountryListingPage/>} />
            <Route path="/adduniversity" element={<AddUniversity/>} />
           

            </Route>
          {/* Private Routes */}
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/userside" element={<UserSide />} />
            <Route path="/agentstaffside" element={<AgentStaffSide />} />
            <Route path="/staffdashboard" element={<StaffDashboard />} />
           
            <Route path='/addstudent' element={<AddStudent/>}/>
            <Route path='/updatestudent' element={<UpdateStudentForm/>}/>
            <Route path='/countrydashboard' element={<CountryDashboard/>}/>
            <Route path='/universitydashboard' element={<UniverstityDashboard/>}/>
            <Route path="/studyabroad" element={<AddCourse />} />
            <Route path="/courseDashboard" element={<CourseDashboard/>} />
            </Route>

        </Routes>
      </Router>
    </div>
  );
}

export default App;




